.rules {
  list-style-type: none;
  margin: 0;
  padding-left: 0;
}

.submit-button {
  width: 100%;
  background-color: #718095;
}
