.container:not(:first-child) {
  margin-top: 10px;
}

.content {
  margin: 0;
  padding-left: 24px;
  list-style-type: square;
}
.content > li {
  font-weight: bold;
  margin-top: 12px;
}

.content > li:first-child {
  margin-top: 0;
}

.inner {
  margin: 0;
  padding: 0;
}

.inner > li {
  font-weight: bold;
  margin-top: 6px;
}

.no-bullet {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
